import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Column, Row } from './defaults';
import logoDark from '../images/logo-dark.svg';
import logoWhite from '../images/logo-white.svg';
import changeIcon from '../images/change-icon.svg';
import { UserRibbon } from './user-ribbon.js';
import { OverviewIcon } from '../images/overview.js';
import { FulfillmentIcon } from '../images/fulfillment.js';
import useAuth from '../utils/useAuth.js';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../utils/api.js';
import { Modal } from './modal.js';
import { fetchBrands } from '../utils/brand.js';
import Select from 'react-dropdown-select';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { NotificationManager } from 'react-notifications';
import { identify, identifyBrand } from '../utils/heap.js';
import { BrandIcon } from '../images/brand-icon.js';
import { BrandingIcon } from '../images/branding.js';

const Logo = styled.img`
    height: 40px;
    margin: 12px 24px;
    cursor: pointer;

    @media (max-width: 768px) {
        height: 40px;
        margin: 14px 0px;
    }

    margin-left: 0px;
    

`;  

const Wrapper = styled(Row)`
    border-bottom: ${props => props.dark ? '1px solid #5963FD' : '1px solid #E0E0E0'};
    align-items: center;
    background: ${props => props.dark ? '#4F59F0' : 'white'};
    padding-right: 24px;
    padding-left: 24px;

    @media (max-width: 768px) {
        padding-right: 20px;
        padding-left: 20px;
    }
`;

const Menu = styled(Row)`
    gap: 32px;
    align-self: stretch;
    align-items: center;
`;

const LinkBox = styled(Column)`
    height: 100%; 
    cursor: pointer;
    border-bottom: ${props => props.selected ? '2px solid #4F59F0' : '2px solid transparent'};   
`;

const LinkWrapper = styled(Row)`
    background: ${props => props.selected ? '#F5F6FA' : 'white'};
    color: ${props => props.selected ? '#4F59F0' : '#5B637D'};
    font-size: 14px;
    padding: 13px 16px;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    position: relative;
`;

const Text = styled.div`
    margin-left: 8px;

    @media (max-width: 762px) {
        display: none;
    }
`;

const Link = ({selected, Icon, children, ...props}) => {
    return <LinkBox selected={selected}>
        <div style={{ flex: 1 }} />
        <LinkWrapper selected={selected} {...props}>
            {Icon && <Icon fill={selected ? '#4F59F0' : '#5B637D'} />}
            <Text>{children}</Text>
        </LinkWrapper>
        <div style={{ flex: 1 }} />
    </LinkBox>
}

const BrandName = styled.div`
    align-self: center;
    font-family: 'Satoshi-Bold';
    color: rgba(79, 89, 240, 1);
`;

const ChangeBrand = styled.img`
    height: 20px;
    width: 20px;
    margin-left: 8px;
    align-self: center;
    cursor: pointer;
`;

const Box = styled(Column)`
    border-radius: 24px;
    background: white;
    padding: 20px;
    padding: 32px 40px;
    width: 400px;
`;

const BoxTitle = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const DropdownInputWrapper = styled(Column)`
    label {
        color: rgba(91, 99, 125, 1);
        font-size: 15px;
        margin-bottom: 10px;
    }
    * {
        outline: none;
        font-family: 'Satoshi-Regular';
    }
    .react-dropdown-select {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        outline: none;
    } 
    .react-dropdown-select-input{
        font-size: 16px;
        padding: 0px;
        font-family: 'Satoshi-Regular';
    }
`;

const DropdownInput = ({ label, options, value, onChange, ...inputProps }) => {
    
    return <DropdownInputWrapper>
        <Select label options={options} values={value ? [value] : []} 
        onChange={(e) => {
            onChange(e[0])
        }}
        {...inputProps} />
    </DropdownInputWrapper>

}


const SelectBrandModal = ({ setVisible }) => {

    const [brand, setBrand] = useState(null);
    const [brands, setBrands] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const _fetchBrands = async () => {
        const result = await fetchBrands()
        setBrands(result)
    }

    useEffect(() => {
        _fetchBrands()
    }, [])

    const submit = async () => {
        try {
            setLoading(true)
            const response = await api.post('/admin/other', { brand_id: brand.value });
            await signInWithCustomToken(getAuth(), response.data.new_token);
            NotificationManager.success(`Impersonating ${brand.label} admin` );
            navigate('/sign-in')
        } catch (err) {
            NotificationManager.error('An error happened.')
        };
        setLoading(false)
    }

    return <Modal onClickOutside={setVisible}>
        <Box onClick={(e) => e.stopPropagation()}>
            <BoxTitle style={{ marginBottom: 15 }}>Select a Brand to Impersonate</BoxTitle>
            <DropdownInput label='Brand' options={brands} value={brand} onChange={(e) => setBrand(e)} />
            <Button loading={loading} disabled={!brand} onClick={submit} style={{ alignSelf: 'flex-end', marginTop: 15 }}>
                Impersonate
            </Button>
        </Box>
    </Modal>

}

export const DashboardHeader = ({ dark = false }) => {

    const { claims, isLoading, currentUserData } = useAuth();
    const [brandName, setBrandName] = useState('');
    const [brandSettingsEnabled, setBrandSettingsEnabled] = useState(false);
    const [impersonateModalVisible, setImpersonateModalVisible] = useState(false);
    const navigate = useNavigate();

    const currentTab = useLocation().pathname;

    const fetchBrandName = async () => {
        const result = await api.get('/admin/single-brand');
        setBrandName(result.data?.name);
        setBrandSettingsEnabled(result.data?.brand_settings_enabled);
    }

    useEffect(() => {
        if (brandName) {
            identify(currentUserData.email,
                currentUserData.uid,
                brandName)
            identifyBrand(brandName)
        }
    }, [brandName])

    useEffect(() => {
        if (claims?.brand) {
            fetchBrandName()
        }
    }, [claims])

    const isSelected = (path) => {
        return currentTab.includes(path)
    }

    return <Wrapper dark={dark}>
        {impersonateModalVisible && <SelectBrandModal setVisible={() => setImpersonateModalVisible(false)}/>}
        <Row style={{ flex: 1 }}>
        <Logo onClick={() => navigate('/dashboard/overview?tab=registered')} src={dark ? logoDark : logoWhite}/>
        <BrandName>{brandName}</BrandName>
        {claims?.brand === 1 && claims?.role === 'SUPER_ADMIN' && <ChangeBrand onClick={() => setImpersonateModalVisible(true)}src={changeIcon} />}
        </Row>
        <Menu>
            {!['TECHNICIAN'].includes(claims?.role) && <Link Icon={OverviewIcon} onClick={() => navigate('/dashboard/overview?tab=registered')} selected={isSelected('/dashboard/overview')}>Overview</Link>}
            {!!brandName && brandSettingsEnabled && <Link Icon={BrandingIcon} onClick={() => navigate('/dashboard/brand-settings')} selected={isSelected('/dashboard/brand-settings')}>Brand Settings</Link>}

            {!isLoading && claims?.brand === 1  && !['TECHNICIAN'].includes(claims?.role)  && <Link onClick={() => navigate('/fulfillment/overview')} selected={isSelected('/fulfillment/overview')} Icon={FulfillmentIcon}>Fulfillment</Link>}
            {!isLoading && claims?.brand === 1 && <Link onClick={() => navigate('/scan')} selected={isSelected('/scan')} Icon={FulfillmentIcon}>Scan</Link>}
        </Menu>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <UserRibbon />
        </div>

    </Wrapper>

}