import axios from 'axios';
import { getAuth } from 'firebase/auth';

const api = axios.create({ baseURL: 
    process.env.REACT_APP_API_URL || 
    `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`});

api.interceptors.request.use(
    async (config) => {
        const auth = getAuth()
        const user = auth.currentUser;
        if (user) {
            const token = await user.getIdToken(); 
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default api;