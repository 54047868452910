/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import useAuth, { authorizedRoles } from './utils/useAuth';
import styled from 'styled-components';
import { Outlet, Route, BrowserRouter as Router, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Column } from "./components/defaults";
import GlobalStyle from "./components/globals";
import { SignIn } from "./pages/sign-in";
import { RecoverPassword } from "./pages/recover-pw";
import { Fulfillment } from "./pages/fulfillment";
import { Overview } from "./pages/overview";
import { NotificationContainer, NotificationManager } from 'react-notifications';

import 'react-notifications/lib/notifications.css';
import { DashboardHeader } from "./components/dashboard-header";
import { Kit } from "./pages/kit";
import { ManageUsers } from "./pages/manage-users";
import { CreatePassword } from "./pages/create-pw";
import { ScanPage } from "./pages/scan";
import { AssignKit } from "./pages/assign-kit";
import { ChangePassword } from "./pages/change-pw";
import { initializeHeap } from "./utils/heap";
import { BrandsPage } from "./pages/brands";
import { LogsPage } from "./pages/logs";
import { BrandSettings } from "./pages/brand-settings";

const DashboardContainer = styled(Column)`
  position: relative;
  min-height: 100vh;
`;

initializeHeap();

const SignedInOnly = () => {

  const { currentUser, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !currentUser) navigate('/sign-in', { replace: true });
    
  }, [isLoading, currentUser])

  if (isLoading) return <div style={{ minHeight: '100vh' }}/>

  return <>
    <DashboardHeader />
    <Outlet />
  </>
}

const TruMeOnly = () => {

  const { currentUser, isLoading, claims } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !currentUser) {
      navigate('/sign-in', { replace: true });
      return
    }
    if (!isLoading && claims && claims?.brand !== 1) {
      const role = authorizedRoles[claims?.role]
      let url = pathname;
      if (role?.authorizedRoutes && !role?.authorizedRoutes.includes(url)){
        url =  role?.defaultRoute || '/dashboard/overview?tab=registered'
      }
      navigate(url, { replace: true });
    }
  }, [isLoading, currentUser, claims])

  if (isLoading) return <div style={{ minHeight: '100vh' }}/>

  return <>
    <DashboardHeader />
    <Outlet />
  </>
}

const SuperAdminOnly = () => {

  const { currentUser, isLoading, claims } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !currentUser) {
      navigate('/sign-in', { replace: true });
    }
    if (!isLoading && claims && claims?.role !== 'SUPER_ADMIN') {
      navigate('/dashboard/overview?tab=registered', { replace: true });
      NotificationManager.error(`You can't access this page.`)
    }
  }, [isLoading, currentUser, claims])

  if (isLoading) return <div style={{ minHeight: '100vh' }}/>

  return <>
    <DashboardHeader />
    <Outlet />
  </>
}

const signedOutExclusions = [
  'sign-in/create-pw',
  'sign-in/reset-pw'
];

const SignedOutOnly = () => {
  const { currentUser, isLoading } = useAuth();
  const navigate = useNavigate();
  const shouldSkipRedirection = () => {
    for (let page of signedOutExclusions) {
      if (window.location.href.includes(page)) return true;
    };
  }
  
  useEffect(() => {
    if (shouldSkipRedirection()) return;
    if (!isLoading && currentUser) navigate('/dashboard/overview?tab=registered', { replace: true });
  }, [currentUser, isLoading]);

  if (isLoading) return <div style={{ minHeight: '100vh' }}/>

  return <Outlet />;
}

const UseScroll = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return <div />

}



function App() {

  return <DashboardContainer>
    <GlobalStyle />
    <NotificationContainer />
    <Router>
      <UseScroll />
      <Routes>
        <Route path="/sign-in" element={<SignedOutOnly />}>
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-in/recover-pw' element={<RecoverPassword />} />
          <Route path='/sign-in/create-pw' element={<CreatePassword />} />
          <Route path='/sign-in/reset-pw' element={<ChangePassword />} />
        </Route>
        <Route path="/dashboard" element={<SignedInOnly />}>
          <Route path="/dashboard/overview" element={<Overview />} />
          <Route path="/dashboard/brand-settings" element={<BrandSettings />} />
          <Route path="/dashboard/kit/:id" element={<Kit />} />
        </Route>
        <Route path='/fulfillment' element={<TruMeOnly />}>
          <Route path='/fulfillment/overview' element={<Fulfillment />} />
        </Route>
        <Route path='/scan' element={<TruMeOnly />}>
          <Route path='/scan' element={<ScanPage />} />
        </Route>
        <Route path='/assign-kit' element={<SignedInOnly />}>
          <Route path='/assign-kit/:id' element={<AssignKit />} />
        </Route>
        <Route path="/root" element={<SuperAdminOnly />}>
          <Route path="/root/users" element={<ManageUsers />} />
        </Route>
        <Route path="/brands" element={<SuperAdminOnly />}>
          <Route path="/brands" element={<BrandsPage />} />
        </Route>
        <Route path="/logs" element={<SuperAdminOnly />}>
          <Route path="/logs" element={<LogsPage />} />
        </Route>
        <Route path="/" element={<Navigate to="/sign-in" replace />} /> {/* Redirect root to /sign-in */}
        
      </Routes>
    </Router>
  </DashboardContainer>
  
}

export default App;
